import axios from 'axios'
import Qs from 'qs'//post data转换提交
import {Toast} from 'vant'

var util = require('@/utils/util.js')

//请求时合并公共请求参数
function mergeCommonRequestData(data) {
  var newdata = Object.assign({}, data)
  newdata['nonce'] = util.randomString(8)
  newdata['timestamp'] = Date.parse(new Date()) / 1000
  newdata['sign'] = util.makeSign(newdata)
  return newdata
}

axios.defaults.withCredentials = true//设置axios请求允许跨域
// 创建axios实例
const service = axios.create({
  baseURL: '/api/wxapp',
  timeout: 10000,// 请求超时时间
  method: 'post',
  // `headers` 是即将被发送的自定义请求头
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  transformRequest: [function (data, headers) {
    if (!data) {
      data = {}
    }
    data = mergeCommonRequestData(data)//合并公共的请求数据
    var data2 = Qs.stringify(data)
    return data2
  }],
  withCredentials: true,
})

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['XX-Api-Version'] = 1
    config.headers['XX-Device-Type'] = 'pc'
    return config
  },
  error => {
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res.code == 404 ) {
      //用户没权限
      window.location.href = res.msg
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    Toast({
      message: error.message,
      duration: 5000
    });
    return Promise.reject(error)
  }
)

export default service
