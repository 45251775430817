import Vue from 'vue'
import App from './App.vue'
import router from './router'
import  "amfe-flexible";
import 'swiper/css/swiper.css';
import 'vant/lib/index.css';
import "./css/index.scss"

Vue.config.productionTip = false
Vue.prototype.setDataArr = function (obj) {
	Object.assign(this.$data,obj);
}

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
