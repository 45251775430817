<template>
    <div class="home-main">
        <banner-swiper :bannerList="bannerList"></banner-swiper>
        <div :class="['home-nav', navSuspension?'suspension':'', (scrollMove && navSuspension)?'animated2 nav-fadeIn':'']">
            <ul class="flex ac">
                <li :class="['home-nav-item',navIndex==1?'active':'']" @click="scrollTo(1)">南平简介</li>
                <li :class="['home-nav-item',navIndex==2?'active':'']" @click="scrollTo(2)">茶旅路线</li>
                <li :class="['home-nav-item',navIndex==3?'active':'']" @click="scrollTo(3)">茶旅资源</li>
                <li :class="['home-nav-item',navIndex==4?'active':'']" @click="scrollTo(4)">民俗文化</li>
                <li :class="['home-nav-item',navIndex==5?'active':'']" @click="scrollTo(5)">特产美食</li>
            </ul>
        </div>
        <div class="home-introduce" id="introduce_view">
            <div class="home-introduce-item" id="nav_1">
                <div class="home-introduce-item-title flex ac jb"><span>南平</span><span>简介</span></div>
                <div class="home-introduce-item-desc">ABOUT US</div>
                <div class="home-introduce-item-main flex jc">
                    <div class="home-introduce-item-main-left introduce-left-img" :style='"background-image:url("+require("@/images/img1.png")+")"'></div>
                    <div class="home-introduce-item-main-content">
                        <div class="tags flex jc">
                            <span v-for="(label,idx) in detailData.scenic_type" :key="idx">{{label}}</span>
                        </div>
                        <div class="text txt-indent">{{detailData.desc}}</div>
                    </div>
                    <div class="home-introduce-item-main-right introduce-code" :style='"background-image:url("+require("@/images/img2.png")+")"'>
                        <div class="wx-qrcode"></div>
                    </div>
                </div>
                <div class="home-introduce-item-bg bg-left" style="left:0;top: 140px;"></div>
            </div>
            <div class="home-introduce-item" id="nav_2">
                <div class="home-introduce-item-title flex ac jb"><span>茶旅</span><span>路线</span></div>
                <div class="home-introduce-item-desc">TEA TOUR ROUTE</div>
                <div class="home-introduce-item-main flex jc">
                    <div class="home-introduce-item-main-list" v-for="(item, idx) in routeList" :key="idx">
                        <div class="home-introduce-item-main-list-img" :style='"background-image:url("+require("@/images/route"+parseInt(idx+1)+".png")+")"'>
                            <div class="home-introduce-item-main-list-code animated fadeInDown" :style="{ backgroundImage : 'url('+ item.code_url + ')' }"></div>
                        </div>
                        <div class="home-introduce-item-main-list-title">{{item.title}}</div>
                    </div>
                </div>
                <div class="home-introduce-item-bg bg-right" style="right:0;top: 10px;"></div>
            </div>
            <div class="home-introduce-item" style="padding:100px 0px;" id="nav_3">
                <div class="home-introduce-item-title flex ac jb"><span>茶旅</span><span>资源</span></div>
                <div class="home-introduce-item-desc">TEA TOUR RESOURCES</div>
                <div class="home-introduce-item-main flex jc ac">
                    <div class="home-introduce-item-main-left introduce-img img-resources" style="margin-right:45px"></div>
                    <div class="home-introduce-item-main-content txt-indent" style="width:700px;">{{detailData.one_text}}</div>
                </div>
                <div class="home-introduce-item-bg bg-left" style="left:0;top: 0px;"></div>
                <div class="home-introduce-item-bg bg-right" style="right:0;bottom: 0px;"></div>
            </div>
            <div class="home-introduce-item" id="nav_4">
                <div class="home-introduce-item-title flex ac jb"><span>民俗</span><span>文化</span></div>
                <div class="home-introduce-item-desc">FOLK CULTURE</div>
                <div class="home-introduce-item-main flex jc ac">
                    <div class="home-introduce-item-main-content txt-indent" style="width:700px;">{{detailData.two_text}}</div>
                    <div class="home-introduce-item-main-right introduce-img img-folk" style="margin-left:23px"></div>
                </div>
                <div class="home-introduce-item-bg bg-left" style="left:0;top: 160px;"></div>
            </div>
            <div class="home-introduce-item" style="margin-top: 80px;" id="nav_5">
                <div class="home-introduce-item-title flex ac jb"><span>特产</span><span>美食</span></div>
                <div class="home-introduce-item-desc">SPECIALTY FOOD</div>
                <div class="home-introduce-item-main flex jc ac">
                    <div class="home-introduce-item-main-left introduce-img img-food" style="margin-right:45px"></div>
                    <div class="home-introduce-item-main-content txt-indent" style="width:700px;">{{detailData.three_text}}</div>
                </div>
                <div class="home-introduce-item-bg bg-right" style="right:0;top: 150px;"></div>
            </div>
        </div>
        <div v-if="showTopButton" @click="backTop" class="home-return-top flex dc ac jc">
            <div class="home-return-top-icon"></div>
            <div class="home-return-top-text">返回顶部</div>
        </div>
        <my-footer></my-footer>
    </div>
</template>
<script>
import BannerSwiper from "@/components/Swiper.vue";
import Footer from "@/components/Footer.vue";
import { Toast } from 'vant';
var util = require("@/utils/util");
export default{
    components: {
        'banner-swiper' : BannerSwiper,
        'my-footer':Footer
    },
    data(){
        return {
            detailData: [],
            bannerList: [],
            routeList: [],
            navIndex: 1,
            scrollTop: 0,
            scrollMove: false, //滚动条是否在进行
            navSuspension: false, //导航悬浮
            showTopButton: false, //返回顶部按钮
        }
    },
    watch: {
        scrollTop(newVal, oldVal){
            let ts = this;
            setTimeout(() => {
                if(newVal == window.scrollY) {
                    ts.scrollMove = false;
                    ts.changeEvent(newVal);
                };
            }, 100);
            if(!ts.scrollMove){
                ts.scrollMove = true;
            }
        }
    },
    async created(){
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });
        await this.getDetail();
        await this.getRoute();
        Toast.clear();
    },
    mounted(){
        window.addEventListener('scroll', this.monitorScroll)
    },
    destroyed () {
        window.removeEventListener('scroll', this.monitorScroll)
    },
    methods: {
        getDetail(){
            let ts = this;
            return new Promise( (resolve) => {
                util.requests("post", {
                    url: "pc/scenicDetails"
                }).then((res) => {
                    if (res.code) {
                        ts.setDataArr({
                            detailData: res.data,
                            bannerList: res.data.logo
                        })
                    }else{
                        Toast.fail(res.msg);
                    }
                    resolve()
                }).catch(()=>{
                    resolve()
                })
            })
        },
        getRoute(){
            let ts = this;
            return new Promise( (resolve) => {
                util.requests("post", {
                    url: "pc/getList"
                }).then((res) => {
                    if (res.code) {
                        ts.setDataArr({
                            routeList: res.data
                        })
                    }else{
                        Toast.fail(res.msg);
                    }
                    resolve()
                }).catch(()=>{
                    resolve()
                })
            })
        },
        // 点击图片回到顶部方法，加计时器是为了过渡顺滑
        backTop () {
           window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        },
        scrollTo(id){
            var pageId = document.querySelector("#nav_"+id);
            let toTop = pageId.offsetTop - 80;
            switch(id)
            {
                case 1:
                    toTop -= 70;
                    break;
                case 3:
                    toTop += 80;
                    break;
                default:
                    break;
            }
            window.scrollTo({
                top: toTop,
                behavior: "smooth"
            })
        },
        monitorScroll(){
            const that = this
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            that.scrollTop = scrollTop
            if (scrollTop > 500) {
                that.showTopButton = true
            } else {
                that.showTopButton = false
            }
        },
        changeEvent(currScrollTop){
            //导航悬浮判断
            let navScrollTop = document.querySelector("#introduce_view").offsetTop - 50;
            if(currScrollTop>navScrollTop){
                this.navSuspension = true;
            }else{
                this.navSuspension = false;
            }

            //锚点定位判断
            let currNavId = 0;
            for(let i=1;i<6;i++){
                var navTop = document.querySelector("#nav_"+i).offsetTop;
                if(currScrollTop > (navTop-100)){
                    currNavId = i;
                }
            }
            this.navIndex = currNavId > 0 ? currNavId : 1;
        }
    }
}
</script>