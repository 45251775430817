<template>
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, idx) in bannerList" :key="idx">
                <div v-if="item.indexOf('@') > -1" :style='"background-image:url("+require("@/images/banner.png")+")"' class="images"></div>
                <div v-else :style="{ backgroundImage : 'url('+ item + ')' }" class="images"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from "swiper"
export default {
    name: 'my-swiper',
    props: {
        bannerList: Array
    },
    data() {
        return {
        }
    },
    watch: {
        bannerList(){
            const ts = this;
            ts.$nextTick(() => {
                ts.initSwiper();
            });
        }
    },
    methods: {
        initSwiper(){
            new Swiper(".swiper-container", {
               autoplay: true,
               loop: true
            });
        },
    }
}
</script>
<style scoped lang="scss">
.swiper-container{
    width: 100%;
}
.swiper-slide .images {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
</style>